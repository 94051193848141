
import Vue from 'vue'
export default Vue.extend({
    components: {
        NMenuBar: () => import('@/components/NMenuBar.vue'),
        NBackground: () => import('@/components/NBackground.vue'),
        NLanding: () => import('@/components/NLanding.vue'),
        NShop: () => import('@/components/NShop.vue'),
        NFooter: () => import('@/components/NFooter.vue'),
        NArt: () => import('@/components/NArt.vue'),
        NProjects: () => import('@/components/NProjects.vue'),
        NPlug: () => import('@/components/NPlug.vue'),
        NContact: () => import('@/components/NContact.vue')
    },

    data() {
        return {
            step: 1
        }
    }
})
